import { graphql, Link } from "gatsby";
import Markdown from "markdown-to-jsx";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import IconArrow from "../assets/icons/arrow-right.svg";
import { Layout, PortfolioCardGrid } from "../components";
import * as styles from "../styles/project.module.scss";

class Project extends Component {
  render() {
    const path = typeof window !== "undefined" ? window.location.pathname : "";

    const projectCards = this.props.data.projectCards.nodes
      .map((item) => {
        item.text = "LEARN MORE";
        return item;
      })
      .filter(
        (item) =>
          item.name.split("-")[1].toLowerCase() !==
          path?.split("/").slice(-2, -1)[0]
      );

    const projectMdx = Object.fromEntries(
      this.props.data.projectMdx.nodes.map((mdx) => [
        mdx.name,
        mdx.childMdx?.body ?? mdx.publicURL,
      ])
    );

    return (
      <Layout layoutClassName="project-page" alwaysColored={false}>
        <div className={styles.projectpage}>
          <section className={styles.hero}>
            <img
              src={projectMdx.banner}
              alt={`${projectMdx.title} banner`}
              className={styles.banner}
            />
            <div className={styles.filter}></div>
            <Row className={styles.heroContainer}>
              <Col xs={12}>
                <Link to="/portfolio/" className={styles.arrowLink}>
                  <IconArrow className={styles.backArrow} />
                  <span>BACK TO PORTFOLIO</span>
                </Link>
              </Col>
              <Col>
                <h1>{projectMdx.title}</h1>
                <p className={styles.subtitle}>{projectMdx.subtitle}</p>
              </Col>
            </Row>
          </section>
          <section className={styles.introduction}>
            <Row className={styles.body}>
              <Col lg={6}>
                {/* <h6 className={styles.header}>{projectMdx.header}</h6> */}
                <h2 className={styles.title}>About the Product</h2>
              </Col>
              <Col className={styles.details} xs={12} lg={6}>
                <p>{projectMdx.introduction}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}></Col>
              <Col xs={12} lg={6}>
                <hr className={styles.computerHr} />
                {projectMdx.timeline && (
                  <>
                    <div className={styles.specs}>
                      <h2 className={styles.title}>Timeline</h2>
                      <p className={styles.text}>{projectMdx.timeline}</p>
                    </div>
                    <hr />
                  </>
                )}
                {projectMdx.domain && (
                  <>
                    <div className={styles.specs}>
                      <h2 className={styles.title}>Domain</h2>
                      <a
                        href={`https://${projectMdx.domain}`}
                        className={`${styles.text} ${styles.domain}`}
                      >
                        {projectMdx.domain}
                      </a>
                    </div>
                    <hr />
                  </>
                )}
                {projectMdx.services && (
                  <>
                    <div className={styles.specs}>
                      <h2 className={styles.title}>Services</h2>
                      <Markdown className={styles.text}>
                        {projectMdx.services}
                      </Markdown>
                    </div>
                    <hr />
                  </>
                )}
                <div className={styles.specs}>
                  <h2 className={styles.title}>Technologies</h2>
                  <Markdown className={styles.text}>
                    {projectMdx.technologies}
                  </Markdown>
                </div>
              </Col>
            </Row>
          </section>
          <section className={styles.description}>
            <hr />
            <Row>
              <Col lg={6}>
                <h6 className={styles.title}>Team Composition</h6>
              </Col>
              <Col xs={12} lg={6}>
                <Markdown>{projectMdx.teamComposition}</Markdown>
              </Col>
            </Row>
            <hr />
            {projectMdx.challenge && (
              <>
                <Row>
                  <Col lg={6}>
                    <h6 className={styles.title}>Challenge</h6>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Markdown>{projectMdx.challenge}</Markdown>
                  </Col>
                </Row>
                <hr />
              </>
            )}
            {projectMdx.solution && (
              <Row>
                <Col lg={6}>
                  <h6 className={styles.title}>Solution</h6>
                </Col>
                <Col xs={12} lg={6}>
                  <Markdown>{projectMdx.solution}</Markdown>
                </Col>
              </Row>
            )}
          </section>
          {projectCards.length > 0 && (
            <section className={styles.otherProjects}>
              <h3>Our other projects</h3>
              <PortfolioCardGrid cards={projectCards} urlPrefix="/portfolio" />
            </section>
          )}
        </div>
      </Layout>
    );
  }
}

export default Project;

export const query = graphql`
  query ($project: String!) {
    projectCards: allFile(
      filter: { relativeDirectory: { eq: "assets/projects/cards" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        name
        relativeDirectory
        publicURL
      }
    }
    projectMdx: allFile(filter: { relativeDirectory: { eq: $project } }) {
      nodes {
        childMdx {
          body
        }
        name
        publicURL
      }
    }
  }
`;
